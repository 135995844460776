<template>
  <div
    class="card card-profile company-card"
  >
    <div class="card-body position-relative p-1 pt-5 px-md-2">
      <div class="profile-image-wrapper">
        <div
          class="profile-image"
          :set="random = getRandomColor(value.name )"
        >
          <b-avatar
            button
            size="100"
            :variant="random.color"
            :text="random.character"
            class="align-baseline"
          />
        </div>
      </div>
      <h3
        class="text-ellipsis"
        :title="value.name"
      >
        {{ value.name }}
      </h3>
      <h6
        class="text-muted text-ellipsis"
        :title="value.address"
      >
        {{ value.address }}
      </h6>
      <hr class="mb-2">
      <div class="d-flex justify-content-between">
        <div>
          <h6 class="text-muted font-weight-bold">
            {{ $t('Users') }}
          </h6>
          <h6 class="mb-0">
            {{ value.users.total }}
          </h6>
        </div>
        <div>
          <h6 class="text-muted font-weight-bolder">
            {{ $t('Projects') }}
          </h6>
          <h6 class="mb-0">
            {{ value.projects.total }}
          </h6>
        </div>
        <div>
          <h6 class="text-muted font-weight-bolder">
            {{ $t('Start Day') }}
          </h6>
          <h6 class="mb-0 text-capitalize">
            {{ value.weekStartDay.toLowerCase() }}
          </h6>
        </div>
        <div>
          <h6 class="text-muted font-weight-bolder">
            {{ $t('Tracking Type') }}
          </h6>
          <h6 class="mb-0">
            {{ value.trackingType || 'N/A' }}
          </h6>
        </div>
      </div>
      <div
        class="d-flex pt-2 justify-content-center"
        style="min-height: 65px;"
      >
        <b-button
          v-if="value.projects.total"
          v-b-tooltip.top="$t('Projects of Company')"
          variant="outline-success"
          style="padding: 14px;"
          @click="goToCompany"
        >
          <feather-icon icon="InfoIcon" /> {{ $t('Projects') }}
        </b-button>
        <b-button
          v-if="permissions.hasCompanyEditPermission"
          v-b-tooltip.top="$t('Edit Company')"
          variant="outline-primary"
          class="ml-2"
          style="padding: 14px;"
          @click="$emit('editCompany',value)"
        >
          <feather-icon icon="EditIcon" /> {{ $t('Edit') }}
        </b-button>
        <b-button
          v-if="permissions.hasInvitePermission"
          v-b-modal.invite-user-modal
          v-b-tooltip.top="$t('Invite user to company')"
          variant="outline-success"
          class="ml-2"
          style="padding: 14px;"
          @click="$emit('inviteUser',value)"
        >
          <feather-icon icon="UserPlusIcon" /> {{ $t('Invite') }}
        </b-button>
      </div>
    </div>

  </div>
</template>
<script>
import {
  BAvatar, BButton,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BAvatar,
    BButton,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    permissions: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      self: getUserData(),
    }
  },
  methods: {
    goToCompany() {
      this.$router.push({
        name: 'companies-projects',
        params: {
          uid: this.value.uuid,
          hasInvitePermission: this.permissions.hasInvitePermission,
          company: this.value.name,
        },
      })
    },
  },
}
</script>
<style scoped>
.text-ellipsis{
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.more-actions{
  top: 12px;
  right: 18px;
}
</style>

<style>
.dark-layout .company-card .btn-link {
  color: #f1f1f1 !important;
}
</style>
