<template>
  <div>
    <b-overlay
      :show="isProcessing"
      class="container"
    >
      <!-- Progress Steps -->
      <b-row class="align-items-start w-100 mb-2 mb-md-4">
        <!-- Step 1 -->
        <b-col
          v-for="section, index in sections"
          :key="section.title"
          class="d-flex align-items-center w-100"
        >
          <div
            class="text-center"
            :class="index < currentStep || mode === 'update' ? 'cursor-pointer' : ''"
            @click="(index < currentStep || mode === 'update') && $emit('change-step', index)"
          >
            <b-avatar
              class="flex-shrink-0"
              :class="{
                'bg-success': (index < currentStep || mode === 'update') && index !== currentStep,
                'bg-primary': index === currentStep,
                'bg-secondary': index > currentStep
              }"
              size="40"
            >
              <feather-icon
                :icon="section.icon"
                :class="index > currentStep && mode === 'create' ? 'text-muted' : ''"
                size="20"
              />
            </b-avatar>
            <small
              class="d-none d-md-block"
              :class="index > currentStep && mode === 'create' ? 'text-muted' : ''"
            >{{ section.title }}</small>
          </div>
          <div
            v-if="index !== sections.length - 1"
            class="progress w-100 position-relative"
            :style="{ height: '0.25rem', left: !IS_MOBILE() ? '24px' : '12px' }"
          >
            <div
              class="progress-bar bg-primary"
              :class="index < currentStep ? 'bg-primary' : 'bg-secondary'"
              role="progressbar"
              style="width: 100%"
            />
          </div>
        </b-col>
      </b-row>

      <div
        v-for="section, index in sections"
        :key="section.title"
      >
        <div v-if="index === currentStep">
          <!-- Section Title -->
          <b-alert
            class="d-md-none d-flex align-items-center mb-2 p-1"
            variant="secondary"
            show
          >
            <feather-icon
              :icon="section.icon"
              size="24"
              class="mr-1"
            />
            <h4 class="mb-0">
              {{ section.title }}
            </h4>
          </b-alert>

          <!-- Section Body -->
          <slot :name="index" />
        </div>
      </div>

      <!-- Footer -->
      <b-row class="w-100 justify-content-between my-2">
        <b-col cols="6">
          <b-button
            v-if="showDiscard || currentStep > 0"
            :disabled="isProcessing"
            :variant="currentStep === 0 ? 'danger' : 'secondary'"
            @click="currentStep === 0 ? $emit('discard') : $emit('change-step', currentStep - 1)"
          >
            {{ currentStep === 0 ? $t('Discard') : $t('Previous') }}
          </b-button>
        </b-col>

        <b-col
          cols="6"
          class="text-right"
        >
          <b-button
            :disabled="isProcessing"
            :variant="currentStep === sections.length - 1 ? 'success' : 'primary'"
            @click="currentStep === sections.length - 1 ? $emit('finish') : $emit('change-step', currentStep + 1)"
          >
            {{ currentStep === sections.length - 1 ? $t('Finish') : $t('Next') }}
          </b-button>
        </b-col>
      </b-row>
    </b-overlay>

  </div>
</template>

<script>
import {
  BRow, BCol, BButton, BOverlay, BAlert, BAvatar,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BOverlay,
    BAlert,
    BAvatar,
  },
  props: {
    sections: {
      type: Array,
      required: true,
    },
    currentStep: {
      type: Number,
      default: () => 0,
    },
    isProcessing: {
      type: Boolean,
      default: () => false,
    },
    showDiscard: {
      type: Boolean,
      default: () => true,
    },
    mode: {
      type: String,
      default: () => 'create',
    },
  },
}
</script>
