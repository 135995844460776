<template>
  <b-card no-body>
    <validation-observer ref="simpleRules">
      <div class="mt-2 mx-auto w-100">
        <wizard
          :mode="form.uuid ? 'update' : 'create'"
          :sections="sections"
          :current-step="currentStep"
          :is-processing="isProcessing"
          :show-discard="showDiscard"
          @change-step="step => changeStep(step)"
          @finish="handleOk"
          @discard="$bvModal.show('confirm-discard-modal')"
        >
          <!-- Company Info -->
          <template #0>
            <company-info-form
              style="min-height: 160px;"
              :form="form"
              :finalizing="finalizing"
              :hidden-column="hiddenColumn"
            />
          </template>

          <!-- Work Settings -->
          <template #1>
            <work-settings-form
              style="min-height: 160px;"
              :form="form"
              :tracking-type-list="trackingTypeList"
              :time-zones="timeZones"
              :day-of-week="dayOfWeek"
              :finalizing="finalizing"
              :mode="mode"
            />
          </template>

          <!-- Management Fee Settings -->
          <template #2>
            <management-fee-form
              style="min-height: 160px;"
              :form="form"
            />
          </template>
        </wizard>
      </div>
    </validation-observer>

    <confirm-modal
      modal="confirm-discard-modal"
      :title="$t('Discard Changes')"
      :ok-title="$t('Yes')"
      ok-variant="danger"
      :cancel-title="$t('No')"
      :alert="$t('alerts.discard-info')"
      alert-variant="danger"
      icon-size="48"
      icon-color="danger"
      @confirm="$emit('cancel')"
    />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import useApollo from '@/plugins/graphql/useApollo'
import Wizard from '@/views/common/components/Wizard.vue'
import ConfirmModal from '@/views/Wallet/components/reusables/ConfirmModal.vue'
import { dayOfWeek } from './timeValues'
import CompanyInfoForm from './components/CompanyInfoForm.vue'
import WorkSettingsForm from './components/WorkSettingsForm.vue'
import ManagementFeeForm from './components/ManagementFeeForm.vue'

const defaultForm = () => ({
  uuid: null,
  name: '',
  address: '',
  email: '',
  trackingType: 0,
  timezoneOffset: 0,
  weekStartDay: 1,
  ownerEmail: '',
})

export default {
  components: {
    BCard,
    CompanyInfoForm,
    WorkSettingsForm,
    ManagementFeeForm,
    ValidationObserver,
    Wizard,
    ConfirmModal,
  },
  props: {
    mode: {
      type: String,
      default: () => 'create',
    },
    companyUid: {
      type: String,
      default: () => '',
    },
    finalizing: {
      type: Boolean,
      default: false,
    },
    showDiscard: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      required,
      email,
      form: {
        ...defaultForm,
        managementFeeSettings: {
          type: null,
          value: 0,
        },
      },
      hiddenColumn: [],
      isProcessing: false,
      trackingTypeList: [
        { title: 'INTERACTIVE', value: 'INTERACTIVE' },
        { title: 'ALL', value: 'ALL' },
      ],
      dayOfWeek,
      timeZones: [],
      sections: [
        {
          title: this.$t('Company Info'),
          icon: 'HomeIcon',
        },
        {
          title: this.$t('Work Settings'),
          icon: 'BriefcaseIcon',
        },
        {
          title: this.$t('Management Fee'),
          icon: 'DollarSignIcon',
        },
      ],
      currentStep: 0,
    }
  },
  computed: {
    selectedUid() {
      return this.companyUid || this.$store.state.project.selectedCompany
    },
  },
  watch: {
    mode: {
      handler(val) {
        if (val !== 'create') this.getCompanyInfo()
      },
      immediate: true,
    },
    companyUid: {
      handler(val) {
        if (val) this.getCompanyInfo()
      },
      immediate: true,
    },
  },
  mounted() {
    useApollo.company.getTimeZone().then(response => {
      this.timeZones = response.data.timezones.data.map(item => ({ name: item.name, title: item.displayName }))
    }).catch(error => {
      this.showErrorMessage(error)
    })
  },
  methods: {
    getCompanyInfo() {
      this.isProcessing = true
      useApollo.company.getMyCompanyInfo(this.selectedUid).then(response => {
        const { managementInfo, ...company } = response.data?.company
        this.$store.dispatch('project/STORE_COMPANY', company)
        this.hiddenColumn.push('ownerEmail')

        this.form = {
          ...company,
          timezoneOffset: company?.timezoneOffset?.name,
          managementFeeSettings: {
            type: managementInfo?.settings?.[0].information?.__typename ?? null,
            value: managementInfo?.settings?.[0].information?.value ?? null,
          },
        }

        delete this.form.managementInfo
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => { this.isProcessing = false })
    },
    handleOk() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.isProcessing = true
          const form = {
            companyUid: this.form.uuid,
            address: this.form.address,
            name: this.form.name,
            trackingType: this.form.trackingType,
            weekStartDay: this.form.weekStartDay,
            timezoneOffset: this.form.timezoneOffset,
            email: this.form.email,
            ownerEmail: this.form.ownerEmail,
            managementFeeSettings: this.form.managementFeeSettings?.type ? {
              type: this.form.managementFeeSettings?.type,
              value: Number(this.form.managementFeeSettings?.value),
            } : null,
          }
          if (this.finalizing) {
            this.isProcessing = true
            this.$emit('create-company', form)
          }
          const method = this.mode === 'edit' ? 'updateCompany' : 'createCompany'
          useApollo.company[method](form).then(() => {
            if (this.mode) {
              this.$emit('onSave')
              this.showSuccess(this.mode === 'edit' ? 'Company Updated' : 'Company Created')
            }
            if (this.$route.name !== 'select-project-roadblock') {
              this.getCompanyInfo()
            }
          }).catch(error => {
            this.showErrorMessage(error)
          }).finally(() => { this.isProcessing = false })
        }
      })
    },
    changeStep(step) {
      if (step < 0 && step > this.sections.length - 1) return

      if (step > this.currentStep) {
        this.$refs.simpleRules.validate().then(success => {
          if (success) {
            this.currentStep = step
          }
        })
      } else {
        this.currentStep = step
      }
    },
  },
}
</script>
