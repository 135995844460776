<template>
  <div>
    <b-row>
      <!-- Company Name -->
      <b-col md="6">
        <b-form-group
          v-if="!finalizing"
          :label="$t('Company Name')"
        >
          <validation-provider
            #default="{ errors }"
            name="company name"
            rules="required"
          >
            <b-form-input
              v-model="form.name"
              :state="errors.length > 0 ? false : null"
              :placeholder="$t('Company Name')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <!-- Company Address -->
      <b-col md="6">
        <b-form-group
          v-if="!finalizing"
          :label="$t('Company Address')"
        >
          <validation-provider
            #default="{ errors }"
            name="company address"
            rules="required"
          >
            <b-form-input
              v-model="form.address"
              :state="errors.length > 0 ? false : null"
              :placeholder="$t('Company Address')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <!-- Company Email -->
      <b-col md="6">
        <b-form-group
          v-if="!finalizing"
          :label="$t('Company Email')"
        >
          <validation-provider
            #default="{ errors }"
            name="company email"
            rules="required|email"
          >
            <b-form-input
              v-model="form.email"
              :state="errors.length > 0 ? false : null"
              type="email"
              :placeholder="$t('Company Email')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <!-- Owner Email -->
      <b-col
        v-if="!hiddenColumn.includes('ownerEmail')"
        md="6"
      >
        <b-form-group :label="$t('Owner Email')">
          <validation-provider
            #default="{ errors }"
            name="owner email"
            rules="required|email"
          >
            <b-form-input
              v-model="form.ownerEmail"
              type="email"
              :state="errors.length > 0 ? false : null"
              :placeholder="$t('Owner Email')"
            />
            <div class="d-flex mt-1">
              <feather-icon
                icon="InfoIcon"
                class="mr-1"
                size="32"
              />
              <small>{{ $t('messages.invitaion-email-info') }}</small>
            </div>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BFormGroup, BFormInput } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'

export default {
  components: {
    BFormGroup,
    BFormInput,
    ValidationProvider,
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
    finalizing: {
      type: Boolean,
      default: false,
    },
    hiddenColumn: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
