<template>
  <b-row>
    <!-- Tracking Type -->
    <b-col md="6">
      <b-form-group :label="$t('Tracking Type')">
        <validation-provider
          #default="{ errors }"
          name="tracking type"
          rules="required"
        >
          <v-select
            v-model="form.trackingType"
            :reduce="item => item.value"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="trackingTypeList"
            :placeholder="$t('Select Tracking Type')"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>

    <!-- Start Week -->
    <b-col md="6">
      <b-form-group :label="$t('Start Week On')">
        <validation-provider
          #default="{ errors }"
          name="start week"
          rules="required"
        >
          <v-select
            v-model="form.weekStartDay"
            :reduce="item => item.value"
            :options="dayOfWeek"
            :placeholder="$t('Select Starting Day')"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>

    <!-- Timezone -->
    <b-col md="12">
      <b-form-group :label="$t('Timezone Offset')">
        <validation-provider
          #default="{ errors }"
          name="timezone offset"
          rules="required"
        >
          <v-select
            v-model="form.timezoneOffset"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="timeZones"
            :reduce="zone => zone.name"
            :placeholder="$t('Select a Timezone')"
            :disabled="mode != 'create' ? true : false"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import vSelect from 'vue-select'
import { BRow, BCol, BFormGroup } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    ValidationProvider,
    vSelect,
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
    timeZones: {
      type: Array,
      default: () => [],
    },
    trackingTypeList: {
      type: Array,
      default: () => [],
    },
    dayOfWeek: {
      type: Array,
      default: () => [],
    },
    finalizing: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: () => 'create',
    },
  },
}
</script>
