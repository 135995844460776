<template>
  <div>
    <b-skeleton-wrapper>
      <b-row>
        <b-col
          v-for="count in counts"
          :key="count"
          md="4"
          class="mb-1"
        >
          <b-skeleton
            type="avatar"
            class="mx-auto"
            size="96px"
          />
          <b-skeleton
            type="Image"
            :height="boxHeight"
          />
        </b-col>
      </b-row>
    </b-skeleton-wrapper>
  </div>
</template>

<script>
import {
  BSkeletonWrapper, BSkeleton, BRow, BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BSkeletonWrapper,
    BSkeleton,
    BRow,
    BCol,
  },
  props: {
    counts: {
      type: Number,
      default: () => 1,
    },
    boxHeight: {
      type: String,
      default: () => '240px',
    },
  },
}
</script>
