<template>
  <div>
    <b-row class="align-items-center mb-5">
      <b-col
        cols="12"
        md="6"
      >
        <h3 class="mb-1">
          {{ $t('List of Companies') }}
        </h3>
      </b-col>
      <b-col
        class="d-flex justify-content-end align-items-center"
        cols="12"
        md="6"
      >
        <b-input-group>
          <b-input-group-prepend>
            <div class="border border-2 d-flex justify-content-center align-items-center px-1">
              <feather-icon
                icon="SearchIcon"
                size="20"
              />
            </div>
          </b-input-group-prepend>
          <b-form-input
            v-model="searchTerm"
            :placeholder="$t('Search Companies')"
            @input="searchCompanies(1)"
          />
        </b-input-group>
        <b-button
          v-if="self.isAdmin"
          v-b-tooltip.top="$t('Create Company')"
          variant="primary"
          class="ml-2 w-50"
          @click="showCompanyCreate = true"
        >
          <feather-icon
            icon="PlusCircleIcon"
          />
          {{ $t('Create Company') }}
        </b-button>

        <manage-permissions
          v-if="canManagePermissions"
          :permissions="permissionSlugs"
          class="ml-1"
        />
      </b-col>
    </b-row>

    <project-skeleton
      v-if="showOverlay"
      :counts="3"
    />

    <div
      v-else
      class="company-list"
    >
      <b-row
        v-if="rows.length"
        class="mt-5"
      >
        <b-col
          v-for="company of rows"
          :key="company.id"
          md="4"
          class="mb-3"
        >
          <company-card
            :value="company"
            :permissions="permissions"
            @inviteUser="showInviteUser"
            @editCompany="(value) => companyToEdit = value"
            @showWalletDeposit="showWalletDepositModal"
          />
        </b-col>
      </b-row>
      <b-card
        v-else
        class="text-center p-2"
      >
        {{ $t('messages.no-companies') }}
      </b-card>
    </div>
    <pagination
      v-if="rows.length"
      class="pb-2"
      :total-items="totalItems"
      :per-page="perPage"
      @onPaginationChange="page => { searchTerm ? searchCompanies(page) : getCompanies(page) }"
    />
    <!-- create or edit company -->
    <create-edit
      :show="showCompanyCreate || !!companyToEdit"
      :company="companyToEdit"
      @onSave="getCompanies"
      @close="showCompanyCreate= false; companyToEdit = null"
    />
    <invite-user
      v-if="permissions.hasInvitePermission"
      :selected-company="companySelectedForInvite"
      :company-invite="true"
      @close="companySelectedForInvite=null"
    />
    <!-- Wallet Deposit Modal -->
    <!-- <make-wallet-deposit-modal
      v-if="selectedCompany"
      :company="selectedCompany"
    /> -->

    <!-- show confirm delete modal -->
    <!-- <confirm-delete
      :processing="processing"
      @onConfirm="handleDelete"
    /> -->
  </div>
</template>
<script>
import useApollo from '@/plugins/graphql/useApollo'
import Pagination from '@/views/common/components/Pagination.vue'
import {
  BButton, BFormInput, BInputGroup, BInputGroupPrepend, BCard,
} from 'bootstrap-vue'
// import ConfirmDelete from '@/views/common/components/ConfirmModal.vue'
import EventBus from '@/event-bus'
import { checkPermission } from '@/utils/permissions'
import { getUserData } from '@/auth/utils'
import ManagePermissions from '@/views/common/components/ManagePermissions.vue'
import _ from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import ProjectSkeleton from '@/views/common/components/ProjectSkeleton.vue'
import CompanyCard from './CompanyCard.vue'
import CreateEdit from './CreateEdit.vue'
import InviteUser from '../../Users/InviteUser.vue'
// import MakeWalletDepositModal from './components/MakeWalletDepositModal.vue'

export default {
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    CompanyCard,
    Pagination,
    BButton,
    BCard,
    InviteUser,
    CreateEdit,
    // MakeWalletDepositModal,
    ManagePermissions,
    ProjectSkeleton,
  },
  data() {
    return {
      self: getUserData(),
      searchTerm: '',
      rows: [],
      perPage: 15,
      totalItems: 0,
      showOverlay: true,
      processing: false,
      showCompanyCreate: false,
      companyToEdit: null,
      companySelectedForInvite: null,
      selectedCompany: null,
      permissionSlugs: ['projects.invite', 'company.edit'],
      needPermissionCheck: false,
    }
  },
  computed: {
    ...mapGetters('permissions', ['canManagePermissions', 'canInviteUser', 'canEditCompany', 'getPermissionsCount']),
    permissions() {
      return {
        hasInvitePermission: this.canInviteUser,
        hasCompanyEditPermission: this.canEditCompany,
      }
    },
  },
  created() {
    EventBus.$on('permission-updated', permission => {
      if (this.permissionSlugs.indexOf(permission) !== -1) {
        this.needPermissionCheck = true
        setTimeout(() => this.checkUserPermissions(), 2000)
      }
    })
    this.checkUserPermissions()
    this.getCompanies()
  },
  beforeUnmount() {
    EventBus.$off('permission-updated')
  },
  methods: {
    ...mapActions('permissions', ['setPermissions']),
    checkUserPermissions() {
      if (this.needPermissionCheck || this.getPermissionsCount(this.permissionSlugs) !== this.permissionSlugs.length) {
        checkPermission(this.permissionSlugs, permissions => {
          const hasInvitePermission = permissions['projects.invite'] === true
          const hasCompanyEditPermission = permissions['company.edit'] === true

          const payload = [
            { key: 'setCompanyEditPermission', value: hasCompanyEditPermission },
            { key: 'setUserInvitePermission', value: hasInvitePermission },
          ]
          this.setPermissions(payload)
          this.needPermissionCheck = false
        })
      }
    },
    getCompanies(page = 1) {
      this.showOverlay = true
      useApollo.company.getCompanies({ page, first: this.perPage }).then(response => {
        const { data } = response
        this.rows = data.companies.data
        this.totalItems = data.companies.total
      }).finally(() => { this.showOverlay = false })
    },
    searchCompanies: _.debounce(function (page = 1) {
      this.showOverlay = true
      const params = {
        keyword: this.searchTerm,
        first: this.perPage,
        page,
      }

      useApollo.company.searchCompanies(params).then(response => {
        const { data } = response
        this.rows = data.companies.data
        this.totalItems = data.companies.total
      }).finally(() => { this.showOverlay = false })
    }, 1000),
    handleDelete(value) {
      this.processing = true
      useApollo.company.deleteCompany(value.id).then(() => {
        this.showSuccessMessage({
          data: {
            message: 'Company Successfully deleted',
          },
        })
        this.$emit('closeConfirm')
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => { this.processing = false })
    },
    showConfirmDelete(value) {
      this.$emit('ConfirmIt', {
        id: Number(value.id),
        body: 'Are you sure you want to delete company & its projects',
        title: `Delete ${value.name} ?`,
      })
    },
    showInviteUser(value) {
      this.companySelectedForInvite = value
    },
    showWalletDepositModal(uuid) {
      this.selectedCompany = {
        uuid,
        name: this.rows.find(row => row.uuid === uuid).name,
      }

      this.$nextTick(() => { this.$bvModal.show('wallet-deposit-modal') })
    },
    // editCompany(value) {
    //   this.$emit('EditCompany', value)
    // },
  },
}
</script>

<style scoped>
.company-list {
  margin-top: 96px;
}
</style>
