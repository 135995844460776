<template>
  <b-modal
    id="modal-no-animation"
    ref="my-modal"
    content-class="shadow"
    :title="title"
    :visible="show"
    :modal-class="`modal-${variant}`"
    no-fade
    body-class="p-0"
    size="lg"
    hide-footer
    no-close-on-backdrop
    :ok-title="$t('Accept')"
    :cancel-title="$t('Cancel')"
    @close="$emit('close')"
    @hide="$emit('close')"
  >
    <b-card>
      <company-form
        :company-uid="company ? company.uuid : ''"
        :mode="company ? 'edit' : 'create'"
        :time-zones="timeZones"
        @onSave="handleSave"
        @cancel="$emit('close')"
      />
    </b-card>
  </b-modal>
</template>
<script>
import {
  BModal, BCard,
} from 'bootstrap-vue'
import useApollo from '@/plugins/graphql/useApollo'
import CompanyForm from './CompanyForm.vue'

export default {
  components: {
    BModal,
    BCard,
    CompanyForm,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    company: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      timeZones: [],
    }
  },
  computed: {
    title() {
      return this.company?.name || 'Create Company'
    },
    variant() {
      return this.company ? 'primary' : 'secondary'
    },
  },
  mounted() {
    useApollo.company.getTimeZone().then(response => {
      this.timeZones = response.data.timezones.data.map(item => ({ name: item.name, title: item.displayName }))
    }).catch(error => {
      console.log(error)
    })
  },
  methods: {
    handleSave() {
      this.$emit('onSave')
      this.$emit('close')
    },
  },
}

// import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import { required, email } from '@validations'
// import vSelect from 'vue-select'
// import useApollo from '@/plugins/graphql/useApollo'
// import { dayOfWeek } from './timeValues'

// const defaultForm = () => ({
//   uuid: null,
//   name: '',
//   address: '',
//   email: '',
//   trackingType: 0,
//   timezoneOffset: 0,
//   weekStartDay: 1,
// })
// export default {
//   components: {
//     BModal,
//     ValidationProvider,
//     BForm,
//     BFormInput,
//     BFormGroup,
//     ValidationObserver,
//     vSelect,
//     BSpinner,
//     BButton,
//     BOverlay,
//   },
//   directives: {
//     'b-modal': VBModal,
//   },
//   data() {
//     return {
//       required,
//       email,
//       title: 'Create Company',
//       isProcessing: false,
//       form: {
//         ...defaultForm(),
//       },
//       trackingTypeList: [
//         { title: 'INTERACTIVE', value: 'INTERACTIVE' },
//         { title: 'ALL', value: 'ALL' },
//       ],
//       timeZones: [],
//       showingProcessing: false,
//       reportingTimezones: [],
//       dayOfWeek,
//     }
//   },
//   mounted() {
//     const self = this
//     useApollo.company.getTimeZone().then(response => {
//       this.timeZones = response.data.timezones.data.map(item => ({ value: item.offset, title: item.name }))
//     }).catch(error => {
//       console.log(error)
//     })
//     this.$parent.$on('CreateCompany', () => {
//       if (self.$refs['my-modal']) {
//         self.$refs['my-modal'].show()
//         self.isProcessing = false
//         self.form = defaultForm()
//       }
//     })
//     this.$parent.$on('EditCompany', value => {
//       if (self.$refs['my-modal']) {
//         this.title = 'Edit Company'
//         this.showingProcessing = true
//         self.$refs['my-modal'].show()
//         self.form = defaultForm()
//         useApollo.company.getCompany(value.uuid).then(response => {
//           this.form = { ...this.form, ...response.data.company }
//           this.showingProcessing = false
//         }).catch(error => {
//           this.showErrorMessage(error)
//         })
//       }
//     })
//   },
//   methods: {
//     handleOk() {
//       // bvModalEvt.preventDefault()
//       this.$refs.simpleRules.validate().then(success => {
//         if (success) {
//           this.isProcessing = true
//           const formData = { ...this.form }
//           formData.timezoneOffset = this.form.timezoneOffset.title
//           if (formData.uuid) {
//             formData.id = Number(formData.id)
//             this.handleUpdate(formData)
//             return
//           }
//           useApollo.company.getCreateCompany({ ...formData, timezones: [] }).then(() => {
//             this.$refs['my-modal'].toggle('#toggle-btn')
//             this.$emit('onSave')
//             this.showSuccessMessage({
//               data: {
//                 message: 'Company Successfully created',
//               },
//             })
//           }).catch(error => {
//             this.showErrorMessage(error)
//           }).finally(() => { this.isProcessing = false })
//         }
//       })
//     },
//     handleUpdate(formData) {
//       console.log(this.reportingTimezones.map(rt => rt.title))
//       this.$refs.simpleRules.validate().then(success => {
//         if (success) {
//           this.isProcessing = true
//           useApollo.company.updateCompany({
//             companyUid: this.form.uuid,
//             address: this.form.address,
//             name: this.form.name,
//             trackingType: this.form.trackingType,
//             weekStartDay: this.form.weekStartDay,
//             timezoneOffset: formData.timezoneOffset,
//             email: this.form.email,
//             timezones: this.reportingTimezones.map(rt => rt.title),
//           }).then(() => {
//             this.showSuccess('Company Updated')
//             this.$refs['my-modal'].toggle('#toggle-btn')
//             this.$emit('onSave')
//           }).catch(error => {
//             this.showErrorMessage(error)
//           }).finally(() => { this.isProcessing = false })
//         }
//         this.showSuccessMessage({
//           data: {
//             message: 'Company Successfully Updated',
//           },
//         })
//       }).catch(error => {
//         this.showErrorMessage(error)
//       }).finally(() => { this.isProcessing = false })
//     },
//   },
// }
</script>
