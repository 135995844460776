<template>
  <b-row>
    <b-col md="8">
      <b-form-group
        :label="$t('management-fee.type')"
      >
        <b-form-radio-group
          v-model="form.managementFeeSettings.type"
          class="d-flex flex-column"
          :disabled="!!form.uuid"
        >
          <b-form-radio
            v-for="type in managementFeeTypes"
            :key="type.value"
            :value="type.value"
            name="management-fees"
            class="mb-2"
          >
            <h6 class="mb-0">
              {{ type.text }}
            </h6>
            <small>{{ type.description }}</small>
          </b-form-radio>
        </b-form-radio-group>
      </b-form-group>
    </b-col>

    <b-col
      v-if="form.managementFeeSettings.type"
      md="4"
    >
      <validation-provider
        v-slot="{ errors }"
        name="value"
        :rules="`required|min_value:1|${form.managementFeeSettings.type === 'Percentage' ? 'percent' : ''}`"
      >
        <b-form-group
          :label="$('Fee')"
          :state="errors.length > 0 ? false : null"
        >
          <b-input-group>
            <template
              v-if="form.managementFeeSettings.type === 'MonthlyCharge'"
              #prepend
            >
              <b-input-group-text>$</b-input-group-text>
            </template>

            <template
              v-else
              #append
            >
              <b-input-group-text>%</b-input-group-text>
            </template>

            <b-form-input
              v-model="form.managementFeeSettings.value"
              :placeholder="$t('Value')"
              type="number"
              :disabled="!!form.uuid"
            />
          </b-input-group>

          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BInputGroup, BInputGroupText, BFormRadioGroup, BFormRadio,
} from 'bootstrap-vue'
import { ValidationProvider, extend } from 'vee-validate'

extend('percent', value => {
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(value)) return 'Share percent should be a number'
  if (value > 0 && value <= 100) return true
  return 'Percentage value should be between 1 and 100'
})

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BInputGroupText,
    BFormInput,
    BFormRadioGroup,
    BFormRadio,
    ValidationProvider,
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      managementFeeTypes: [
        { value: null, text: this.$t('None') },
        { value: 'MonthlyCharge', text: this.$t('management-fee.per-user-per-month'), description: this.$t('management-fee.per-user-per-month-info') },
        { value: 'PercentageBased', text: this.$t('management-fee.percentage'), description: this.$t('management-fee.percentage-info') },
      ],
    }
  },
}
</script>
